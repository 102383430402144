//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


  import NormalHead from '@/views/components/ishop/header.vue'
  import DropdownFilter from '@/views/components/ishop/dropdownFilter.vue'
  import RestaurantList from '@/views/components/ishop/shopList.vue'
  import { miceService } from '@/service/miceService'
  export default {
    name: 'mytakeaway',
    components: {
      NormalHead,
      DropdownFilter
    },
    data() {
      return {
        isEmptyShopData: true,
        headValue: {
          name: "我推荐的外卖/茶歇",
          haveBack: true
        },
        params: {
          itemType: 7,
          cityId: sessionStorage.getItem("pingRegionId"),
          hospitalId: sessionStorage.getItem("hospitalId"),
          channel: localStorage.getItem('channel') || sessionStorage.getItem('channel') || 'h5'
        },
        shopData: []
      }
    },
    created() {
      this.queryMyShops()
    },
    mounted() {
      this.Bus.$on('paramsHpId', params => {
          this.params.hospitalId  = params.hospitalId
          this.queryMyShops()
      });
      this.Bus.$on('paramsCityId', params => {
          this.params.cityId  = params.cityId
          this.params.hospitalId = ''
          this.queryMyShops()
      });
    },
    methods: {
      jump() {
        this.$router.push('/step1Input')
      },
      queryMyShops: async function() {
        let params = this.params
        const res = await miceService.queryMyShops(params)
        if(res.status) {
          this.isEmptyShopData = res.data.customed.length <= 0 && res.data.selected.length <= 0;
          this.shopData = res.data;
        }
      },
      changeMyshop(item,index){
        this.messageBox({
          message: `确认删除该推荐餐厅`,
          showCancelButton:true,
          cancelButtonText:"否",
          confirmButtonText: '是'
        }).then(action => {
          if(action == "confirm"){
            this.removeMyShop(item,index)
          }
        });
      },
      removeMyShop: async function(item,index) {
        let input = {
          recommendId:  item.recommendId
        }
        const res = await miceService.removeMyShop(input)
        if(res.status){
          this.shopData.customed.splice(index, 1)
        }
      }
    }
  }
